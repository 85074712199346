<template>
  <div>
    <LoadingState v-if="isLoading" />
    <a-row v-else :gutter="16">
      <a-col :lg="24" :xl="6" v-sticky="{ zIndex: 99, stickyTop: 10, disabled: isDisabledSticky }">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menusSelection" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
          <!-- <div v-if="generalReport.kelas_tahun_ajaran" class="card-body d-flex flex-column align-items-center justify-content-center">
            <div class="kit__utils__avatar kit__utils__avatar--size130 kit__utils__avatar--rounded p-1 border border-primary shadow">
              <img v-if="generalReport.kelas_tahun_ajaran.murid.jenis_kelamin === 'Laki-Laki'" src="@/assets/Male-Peci.svg" alt="User 11" />
              <img v-else-if="generalReport.kelas_tahun_ajaran.murid.jenis_kelamin === 'Perempuan'" src="@/assets/Female-Hijab.svg" alt="User 11" />
            </div>
            <div class="text-center">
              <h5>{{generalReport.kelas_tahun_ajaran.murid.nis}}</h5>
              <h5>{{generalReport.kelas_tahun_ajaran.murid.nama}}</h5>
              <h5>{{generalReport.kelas_tahun_ajaran.murid.jenis_kelamin}}</h5>
              <div class="d-flex justify-content-center align-items-center my-3">
                <a-space size="middle">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Phone Number</span>
                    </template>
                    <a :href="generalReport.kelas_tahun_ajaran.murid.nomor_telepon ? `tel:${generalReport.kelas_tahun_ajaran.murid.nomor_telepon}` : '#'">
                      <a-button size="large" type="default" shape="circle" icon="phone" />
                    </a>
                  </a-tooltip>
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>E-Mail</span>
                    </template>
                    <a :href="generalReport.kelas_tahun_ajaran.murid.email ? `mailto:${generalReport.kelas_tahun_ajaran.murid.email}` : '#'">
                      <a-button size="large" type="default" shape="circle" icon="mail" />
                    </a>
                  </a-tooltip>
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Parent Phone</span>
                    </template>
                    <a :href="generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nomor_telepon ? `tel:${generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nomor_telepon}` : '#'">
                      <a-button size="large" type="default" shape="circle" icon="alert" />
                    </a>
                  </a-tooltip>
                </a-space>
              </div>
            </div>
            <table class="table mt-2 mb-1">
              <tr>
                <td class="text-left">NISN</td>
                <td>:</td>
                <td class="text-left w-75">{{generalReport.kelas_tahun_ajaran.murid.nisn ? generalReport.kelas_tahun_ajaran.murid.nisn : '-' }}</td>
              </tr>
              <tr>
                <td class="text-left">Class</td>
                <td>:</td>
                <td class="text-left w-75">{{generalReport.kelas_tahun_ajaran.kela.tingkat}}-{{generalReport.kelas_tahun_ajaran.kela.simbol}}</td>
              </tr>
              <tr>
                <td class="text-left">SPP</td>
                <td>:</td>
                <td class="text-left w-75">
                  <a-tag :color="generalReport.kelas_tahun_ajaran.murid.status_spp === 'paid' ? 'green' : 'red'">{{generalReport.kelas_tahun_ajaran.murid.status_spp}}</a-tag>
                </td>
              </tr>
            </table>
            <a-divider >Discover</a-divider>
            <span class="mb-4"></span>
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div> -->
        </div>
      </a-col>
      <a-col :lg="24" :xl="18">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{ menuName }}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-1">
            <template v-if="menuActive === 1">
              <EmptyState class="my-4" :heading="generalReportIsViewed.message"
                :description="generalReportIsViewed.description" v-if="!generalReportIsViewed.status" />
              <div v-else :class="isDesktop ? ['d-flex', 'justify-content-center'] : []">
                <div class="mb-4">
                  <a-button v-if="!generalReport.is_verify_walikelas" :class="!isDesktop ? ['w-100'] : []"
                    :loading="loadingVerify" @click.prevent="verifyReport"
                    class="bg-warning border-warning text-white mr-3" icon="check-square">
                    Verify this Report
                  </a-button>
                  <a-button v-else disabled :class="!isDesktop ? ['w-100'] : []" :loading="loadingVerify" class="mr-3"
                    icon="check-square">
                    Report is Verified
                  </a-button>
                </div>
                <div class="mb-4">
                  <a-button :class="!isDesktop ? ['w-100'] : []" :loading="loadingPrint"
                    @click.prevent="exportReport('print')" class="mr-3" icon="printer">
                    {{ loadingPrint ? "Printing..." : "Print Report" }}
                  </a-button>
                </div>
                <div class="mb-4">
                  <a-button :class="!isDesktop ? ['w-100'] : []" :loading="loadingExport"
                    @click.prevent="exportReport('export')" type="danger" icon="file-pdf" class="mr-3">
                    {{ loadingExport ? "Exporting..." : "Export to PDF" }}
                  </a-button>
                </div>
                <div class="mb-4">
                  <a-button :class="!isDesktop ? ['w-100'] : []" :loading="loadingSend" @click.prevent="sendReport"
                    type="primary" icon="mail">
                    {{ loadingSend ? "Sending..." : "Send Report to Parent" }}
                  </a-button>
                </div>
              </div>
              <!-- <vue-html2pdf
                :html-to-pdf-options="pdfOptions"
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="
                  generalReport.type === 'mid' ? 1100 : 600
                "
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-content-width="100%"
                ref="html2Pdf"
                @beforeDownload="beforeDownload($event, type)"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
              >
                <section slot="pdf-content">
                  <report-mid-view
                    v-if="generalReport.type === 'mid'"
                    :configSchool="configSchool"
                    :generalReport="generalReport"
                  />
                  <report-final-view
                    v-else-if="generalReport.type === 'final'"
                    :configSchool="configSchool"
                    :generalReport="generalReport"
                  />
                </section>
              </vue-html2pdf> -->

              <template v-if="'uuid' in generalReport">
                <template v-if="versionReport == null">
                  <div v-if="reportType === 'mid'" class="d-none">
                    <div id="reportHtml" class="w-100">
                      <report-mid-view :format="isExported
                        ? { width: '650px', height: '1037px' }
                        : null
                        " :configSchool="configSchool" :generalReport="generalReport" />
                    </div>
                  </div>
                  <div v-else-if="reportType === 'final'" class="d-none">
                    <div id="reportHtml" class="w-100">
                      <report-final-view print :format="isExported
                        ? { width: '670px', height: '1045px' }
                        : null
                        " :configSchool="configSchool" :generalReport="generalReport" />
                    </div>
                  </div>
                </template>
                <template v-else-if="versionReport === 1">
                  <div v-if="reportType === 'mid'" class="d-none">
                    <div id="reportHtml" class="w-100">
                      <report-mid-view-v2 print :format="isExported
                        ? { width: '650px', height: '1037px' }
                        : null
                        " :configSchool="configSchool" :dataReport="generalReport" :kumer="kumer" />
                    </div>
                  </div>
                  <div v-else-if="reportType === 'final'" class="d-none">
                    <div id="reportHtml" class="w-100">
                      <!-- ? { width: '670px', height: '1045px' } -->
                      <report-final-view-v2 print :format="isExported
                        ? { width: '670px', height: '1000px' }
                        : null
                        " :configSchool="configSchool" :dataReport="generalReport" :kumer="kumer" />
                    </div>
                  </div>
                </template>
                <!-- print -->
                <template v-if="versionReport == null">
                  <report-mid-view v-if="reportType === 'mid'" :configSchool="configSchool"
                    :generalReport="generalReport" />
                  <report-final-view v-else-if="reportType === 'final'" :configSchool="configSchool"
                    :generalReport="generalReport" page />
                </template>
                <template v-else-if="versionReport == 1">
                  <report-mid-view-v2 v-if="reportType === 'mid'" :configSchool="configSchool" :dataReport="generalReport"
                    :kumer="kumer" />
                  <report-final-view-v2 v-else-if="reportType === 'final'" :configSchool="configSchool"
                    :dataReport="generalReport" :kumer="kumer" page />
                </template>
              </template>
              <!-- print -->
              <!-- <report-final-view v-else-if="generalReport.type === 'final'" :configSchool="configSchool" /> -->
            </template>
            <template v-if="menuActive === 2">
              <report-rp @child-rpp="onChild" />
            </template>
            <template v-if="menuActive === 3">
              <student-progress></student-progress>
            </template>
            <template v-if="menuActive === 4">
              <ReportAchievements :generalReport="generalReport" @fetchDataGeneralReport="fetchDataDetail" />
            </template>
            <template v-if="menuActive === 5">
              <report-activities @child-rpp="onChild" />
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import config from '@/config'
import VueSticky from 'vue-sticky'
// import VueHtml2pdf from 'vue-html2pdf'
import LoadingState from '@/components/app/LoadingState'
import JsPdf from 'jspdf'
const Menu = () => import('@/components/app/MenuV2')
const ReportRp = () => import('@/components/app/Homeroom/ReportRP')
const ReportActivities = () => import('@/components/app/Homeroom/ReportActivities')
const ReportAchievements = () => import('@/components/app/Homeroom/ReportAchievements')
const ReportMidViewV2 = () => import('@/components/app/Report/Mid/v2')
const ReportMidView = () => import('@/components/app/Report/Mid')
const ReportFinalView = () => import('@/components/app/Report/Final')
const ReportFinalViewV2 = () => import('@/components/app/Report/Final/v2')
const EmptyState = () => import('@/components/app/EmptyState')

const StudentProgress = () => import('@/components/app/Homeroom/StudentProgress')
// import StudentProgress from '@/components/app/Homeroom/StudentProgress'
// import QRCode from 'qrcode'

const menus = [
  {
    key: 1,
    menu: 'Report Preview',
  },
  {
    key: 2,
    menu: 'Reward & Punishment',
  },
  {
    key: 3,
    menu: 'Student Progress',
  },
]

export default {
  name: 'DetailHomeroom',
  directives: {
    sticky: VueSticky,
  },
  components: {
    // VueHtml2pdf,
    LoadingState,
    Menu,
    ReportMidView,
    ReportMidViewV2,
    ReportFinalView,
    ReportFinalViewV2,
    ReportRp,
    StudentProgress,
    ReportAchievements,
    // StudentProgress,
    EmptyState,
    ReportActivities,
  },
  data() {
    return {
      kumer: false,
      menus,
      menuActive: 1,
      configSchool: '',
      generalReport: {},
      loadingVerify: false,
      loadingExport: false,
      loadingSend: false,
      loadingPrint: false,
      type: 'print',
      chartData: {
        labels: [],
        series: [],
        fullWidth: true,
      },
      pdfOptions: {
        margin: [10, 10, 10, 10],
        filename: 'File',
        image: {
          type: 'jpeg',
          quality: 0.50,
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          // unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      // base64IsReady: false,
      isExported: false,
      reportType: null,
      versionReport: null,
      isMetadata: false,
      generalReportIsViewed: {
        status: true,
        message: null,
        description: null,
      },
    }
  },
  methods: {
    menuChange(payload) {
      if (payload === 1) {
        this.fetchDataDetail()
      }
      this.menuActive = payload
    },
    // async generateBase64QrCode(uuid) {
    //   try {
    //     console.log(this.base64IsReady)
    //     const data = await QRCode.toDataURL(uuid)
    //     this.base64IsReady = true
    //     return data
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    verifyReport() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to verify this report? If you verify, <b>this report will be static (grade can't be inputted anymore, either by subject's teacher or you)</b> and verified by you</div>
        ),
        onOk: async () => {
          try {
            this.loadingVerify = true
            // this.exportReport('verify')
            await this.$store.dispatch('homeroom/VERIFY_REPORT', { id: this.generalReport.id, by: 'homeroom', generalReport: this.generalReport })
            this.loadingVerify = false
            this.fetchDataDetail()
            this.$notification.success({
              message: 'Success',
              description: 'Report has been verified',
            })
          } catch (err) {
            this.$notification.error({
              message: 'Error',
              description: 'Report has not been verified',
            })
          }
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    sendReport() {
      // console.log(this.generalReport)
      const isParentHaveEmail = this.generalReport.email_perwakilan_orangtua
      if (!isParentHaveEmail) {
        this.$router.push({ name: 'Personal Data Homeroom Teacher', query: { murid: this.generalReport.murid.id } })
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Personal Data Homeroom Teacher'],
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to send this report to the parent? This report will be sent by email automatically</div>
          ),
          onOk: () => {
            this.loadingSend = true
            this.type = 'send'
            // this.$refs.html2Pdf.generatePdf()
            this.exportReport('send')
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      }
    },
    exportReport(type = 'export') {
      if (type === 'export') this.loadingExport = true
      else if (type === ' print') this.loadingPrint = true
      // this.$refs.html2Pdf.generatePdf()
      const data = document.getElementById('reportHtml')
      this.isExported = true
      const doc = new JsPdf({
        // orientation: 'p',
        unit: 'px',
        format: 'a4',
        // putOnlyUsedFonts: true,
      })
      // console.log(data)
      doc.html(data, {
        callback: (doc) => {
          this.isExported = false
          if (type === 'print' || type === 'export') {
            if (type === 'export') doc.save(this.pdfOptions.filename + '.pdf')
            else {
              // doc.autoPrint()
              // doc.output('dataurlnewwindow')
              window.open(URL.createObjectURL(doc.output('blob')))
            }
            this.loadingExport = false
            this.loadingPrint = false
          } else {
            let pdf = doc.output('datauristring')
            const idGeneralReport = this.generalReport.id
            const arr = pdf.split(',')
            pdf = arr[1]
            if (type === 'verify') {
              this.$store.dispatch('homeroom/VERIFY_REPORT', { id: this.generalReport.id, by: 'homeroom', pdf })
                .then(isSuccess => {
                  this.loadingVerify = false
                  this.fetchDataDetail()
                  if (isSuccess) {
                    this.$notification.success({
                      message: 'Success',
                      description: 'Report has been verified',
                    })
                  } else {
                    this.$notification.error({
                      message: 'Error',
                      description: 'Report has not been verified',
                    })
                  }
                })
            } else {
              this.$store.dispatch('homeroom/SEND_REPORT_PARENT_EMAIL', { pdfFile: pdf, idGeneralReport, idGuru: this.user.id })
                .then(isSuccess => {
                  this.loadingSend = false
                  if (isSuccess) {
                    this.$notification.success({
                      message: 'Success',
                      description: 'Report has sent',
                    })
                  } else {
                    this.$notification.error({
                      message: 'Error',
                      description: 'Report has not sent',
                    })
                  }
                })
            }
          }
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
          scale: 0.6,
        },
        margin: [10, 10, 10, 10],
      })
      // doc.text('Hello world!', 10, 10)
      // doc.save('a4.pdf')
    },
    onProgress(event) {
      // this.loadingExport = true
      console.log('Progress  ', event)
    },
    hasStartedGeneration() {
      console.log('Start Generate')
    },
    hasGenerated(event) {
      console.log('Generated', event)
    },
    async beforeDownload({ html2pdf, options, pdfContent }, type) {
      if (type === 'print') {
        await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => { }).save()
        this.loadingExport = false
      } else if (type === 'send') {
        await html2pdf().set(options).from(pdfContent).toPdf().output('datauristring').then((pdf) => {
          const idGeneralReport = this.generalReport.id
          const arr = pdf.split(',')
          pdf = arr[1]
          this.$store.dispatch('homeroom/SEND_REPORT_PARENT_EMAIL', { pdfFile: pdf, idGeneralReport, idGuru: this.user.id })
            .then(isSuccess => {
              this.loadingSend = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description: 'Report has sent',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Report has not sent',
                })
              }
            })
        })
      }
    },
    async fetchDataDetail() {
      try {
        const id = this.$route.params.id
        const idGuru = this.user.id
        this.generalReport = {}
        const { metadata: generalReport, isMetadata, message, description, kumer } = await this.$store.dispatch('homeroom/FETCH_DETAIL_GENERAL_REPORT', { id, idGuru })
        if (kumer) {
          this.kumer = true
        }
        if (!generalReport) {
          this.generalReportIsViewed = {
            status: false,
            message,
            description,
          }
          return this.$notification.error({
            message, description,
          })
        }
        // console.log(generalReport.type)
        // if (generalReport.tipe_report === 'mid') {
        //   // generalReport.group_mata_pelajaran.forEach(e => {
        //   //   // console.log(e)
        //   //   e.mata_pelajaran.forEach(el => {
        //   //     let nilai = 0
        //   //     let totalData = 0
        //   //     for (const key in el) {
        //   //       console.log(`${key} : ${el[key]}`)
        //   //       if (key.includes('kd_knowledge')) {
        //   //         const value = el[key]
        //   //         if (value) {
        //   //           nilai += value
        //   //           totalData++
        //   //         }
        //   //         // console.log(`${key}: ${value}`)
        //   //       }
        //   //     }
        //   //     // console.log(knowledgeValues)
        //   //     const avg = +nilai / totalData
        //   //     el.formatifAvg = avg ? avg.toFixed(2) : null
        //   //     console.log(avg.toFixed(2))
        //   //     console.log('bates')
        //   //   })
        //   // })
        // }
        this.versionReport = generalReport.version
        this.generalReport = generalReport
        this.reportType = generalReport.tipe_report
        this.isMetadata = isMetadata
        const type = this.reportType.toUpperCase()
        const name = generalReport.murid.nama?.toUpperCase() || ''
        const kelas = generalReport.murid.kelas
        const semester = generalReport.tahun_ajaran.semester === 1 ? 'Ganjil' : generalReport.tahun_ajaran.semester === 2 ? 'Genap' : ''
        const tahunAjaran = `${generalReport.tahun_ajaran.tahun} ${semester.toUpperCase()}`
        this.pdfOptions.filename = `REPORT ${type} ${name} (${kelas}) ${tahunAjaran}`
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
      // console.log('this.generalReport fetch', this.generalReport)
    },
    onChild(data) {
      // console.log('children data', data)
      if (data.isActivities) {
        // console.log(data)
        // console.log(this.generalReport.id)
        data.idGeneralReport = this.generalReport.id
        if (data.type === 'add') {
          this.$store
            .dispatch('homeroom/CREATE_REPORT_ACTIVITIES', {
              data,
            })
            .then(res => {
              if (res === true) {
                this.fetchDataDetail()
                this.$notification.success({
                  message: 'Success',
                  description: 'This student R/P has been updated',
                })
              } else {
                this.$notification.error({
                  message: 'Sorry',
                  description: 'This student R/P has not been updated',
                })
              }
            })
        } else if (data.type === 'edit') {
          this.$store
            .dispatch('homeroom/UPDATE_REPORT_ACTIVITIES', {
              data,
            })
            .then(res => {
              // console.log('res', res)
              if (res.isSuccess) {
                this.fetchDataDetail()
                this.$notification.success({
                  message: 'Success',
                  description: 'Student Activities has been updated',
                })
              } else {
                this.$notification.error({
                  message: 'Sorry',
                  description: 'Student Activities has not been updated',
                })
              }
              this.editReportRP = false
              this.modalEditVisible = false
              this.confirmLoading = false
            })
        } else if (data.type === 'delete') {
          this.$store.dispatch('homeroom/DELETE_REPORT_ACTIVITIES', {
            id: data.id,
          })
            .then(res => {
              if (res === 200) {
                this.fetchDataDetail()
                this.$notification.success({
                  message: 'Success',
                  description:
                    'This student Activities has been removed',
                })
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'This student Activities cannot be deleted',
                })
              }
            })
        }
      } else {
        if (data.type === 'add') {
          this.$store
            .dispatch('homeroom/CREATE_REPORT_REWARD_PUNISHMENT', {
              idGuru: this.user.id,
              data: {
                id_general_report: data.id_general_report,
                id_reward_punishment: data.id_reward_punishment,
                frequency: data.frequency,
                note: data.note,
              },
            })
            .then(res => {
              if (res === true) {
                this.fetchDataDetail()
                this.$notification.success({
                  message: 'Success',
                  description: 'This student R/P has been updated',
                })
              } else {
                this.$notification.error({
                  message: 'Sorry',
                  description: 'This student R/P has not been updated',
                })
              }
            })
        } else if (data.type === 'edit') {
          this.$store
            .dispatch('homeroom/UPDATE_REPORT_REWARD_PUNISMENT', {
              data: data.dataEdit,
            })
            .then(res => {
              // console.log('res', res)
              if (res.isSuccess) {
                this.fetchDataDetail()
                this.$notification.success({
                  message: 'Success',
                  description: 'Student Report Reward / Punishment has been updated',
                })
              } else {
                this.$notification.error({
                  message: 'Sorry',
                  description: 'Student Report Reward / Punishment has not been updated',
                })
              }
              this.editReportRP = false
              this.modalEditVisible = false
              this.confirmLoading = false
            })
        } else if (data.type === 'delete') {
          this.$store.dispatch('homeroom/DELETE_REPORT_REWARD_PUNISHMENT', {
            id: data.id,
          })
            .then(res => {
              if (res === 200) {
                this.fetchDataDetail()
                this.$notification.success({
                  message: 'Success',
                  description:
                    'This student Reward / Punishment has been removed',
                })
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'This student Reward / Punishment cannot be deleted',
                })
              }
            })
        }
      }
    },
  },
  created() {
    // console.log(jsPdf)
    this.configSchool = config.school
    this.fetchDataDetail()
    // console.log(this.generateBase64QrCode('tes'))
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    menuName() {
      const { menu } = this.menusSelection.filter(el => el.key === this.menuActive)[0]
      return menu
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    menusSelection() {
      const additionalMenuForReportFinal = {
        key: this.menus[this.menus.length - 1].key + 1,
        menu: 'Achievements',
      }
      const additionalMenuForReportFinal2 = {
        key: this.menus[this.menus.length - 1].key + 2,
        menu: 'Activities',
      }
      // console.log(this.reportType)
      if (this.reportType === 'final') return [...menus, additionalMenuForReportFinal, additionalMenuForReportFinal2]
      return menus
    },
  },
}
</script>

<style></style>
